import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useMemo } from "react";
import { TRIP_LEG_STATUS_MAP_LIST } from "../../../constant/constant";
import { IFullTripDetails, ILeg } from "../../../service/trip";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";

interface IFlightAwareModal {
  visible: boolean;
  tripDetail: IFullTripDetails | null;
  onTrackClickHandler: (tailNumber: string) => void;
  onCloseClickHandler: () => void;
}

interface IFlightAwareDetails {
    tailNumber: string | undefined;
    label: string;
}

const FlightAwareModal = ({
  visible,
  onCloseClickHandler,
  onTrackClickHandler,
  tripDetail,
}: IFlightAwareModal) => {
  const [requiredMessage, setRequiredMessage] = React.useState<undefined | string>();
  const availableLegStatuses: any = [
    TRIP_LEG_STATUS_MAP_LIST[6].id,
    TRIP_LEG_STATUS_MAP_LIST[7].id,
    TRIP_LEG_STATUS_MAP_LIST[8].id,
  ];
  const flightAwareDetails: Array<IFlightAwareDetails> | undefined = useMemo(() => {
    return tripDetail?.legs
      .filter((leg) => {
        return availableLegStatuses.includes(leg.legStatus?.id);
      })
      .map((leg, index) => ({
        tailNumber: leg.aircraft?.tailNumber,
        label: `Leg # ${index + 1} | ${leg.originAirport.code} → ${
          leg.destinationAirport.code
        } | ${leg.aircraft?.name} (${leg.aircraft?.tailNumber})`,
      }));
  }, [tripDetail]);

  const [selectedLeg, setSelectedLeg] = React.useState<IFlightAwareDetails>();
  return (
    <React.Fragment>
      {visible && (
        <Dialog onClose={onCloseClickHandler} className="confirmationMsgPopUp">
          <div className="maverickForm">
            <div className="row">
              <div className="form-group">
                <DropDownList
                  className="customInput"
                  data={flightAwareDetails ?? []}
                  label="Select Leg"
                  textField="label"
                  dataItemKey="tailNumber"
                  value={selectedLeg}
                  onChange={(e) => {
                    setSelectedLeg(e.value);
                    setRequiredMessage(undefined);
                  }}
                />
                <Error>{requiredMessage}</Error>
              </div>
            </div>
          </div>
          <div className="modelFooterAction">
            <Button
              fillMode="outline"
              themeColor={"primary"}
              onClick={() => {
                if(!selectedLeg) {
                  setRequiredMessage("This field is required.");
                } else {
                  onTrackClickHandler(selectedLeg?.tailNumber || "")
                }
               
              }}
            >
              Track
            </Button>

            <Button onClick={onCloseClickHandler}>
              Close
            </Button>
          </div>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default FlightAwareModal;
