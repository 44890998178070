import { HEADER_PERMISSION, RESET_LOGIN_USER_DATA, SET_LOGIN_USER_DATA, SET_USERAZURAD } from "../../constant/redux-const";

export const setLoginUser: any = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: SET_LOGIN_USER_DATA,
    payload: data,
  });
};

export const setUserAzurAD: any = (data: any) => async (dispatch: any) => {
  await dispatch({
    type: SET_USERAZURAD,
    payload: data,
  });
};

export const resetLoginUser: any = () => async (dispatch: any) => {
  await dispatch({
    type: RESET_LOGIN_USER_DATA,
  });
};

export const setHeaderPermission: any = (data:any) => async (dispatch: any) => {

  await dispatch({
    type: HEADER_PERMISSION,
    payload: data,
  });
};
