import React from "react";
import { Link } from "react-router-dom";
import "./tab.scss";


interface ITab {
    title: string,
    key: string,
    navigateUrl?: string
}

interface ITabs {
    activeKey: string | null;
    tabList: Array<ITab>;
    children: React.ReactNode;
    isLink?: boolean;
    dataTestId?: string;
    onClickTab?: (key: string) => void;
};

const Tabs: React.FC<ITabs> = ({activeKey, tabList, children, isLink, dataTestId, onClickTab }) => {
    const checkActive = React.useCallback((key: string, className: string) => {
      return activeKey === key ? className : "";
    }, [activeKey]);

    return (
        <div className="icreon-tab">
            <div className="tabMain">
                {tabList.map(tab => (
                    <div className={`tab-box ${checkActive(tab.key, "active")}`} key={tab.key}>
                        { isLink && tab.navigateUrl ? (
                            <Link to={tab.navigateUrl} className={`tab-title ${checkActive(tab.key, "active")}`} data-testid={`${dataTestId}-${tab.key}`}>
                                {tab.title}
                            </Link>
                        ) : (
                            <div className={`tab-title ${checkActive(tab.key, "active")}`} data-testid={`${dataTestId}-${tab.key}`} onClick={() => onClickTab && onClickTab(tab.key)}>
                                {tab.title}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {children}
      </div>
    );
};

export default React.memo(Tabs);