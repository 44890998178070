import * as type from '../../constant/redux-const';

const initialState:any = {
  loading: false
};

const LoaderReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case type.SET_LOADER:
      return {
        ...state,
        loading: action.flag
      };

    default:
      return state;
  }
}

export default LoaderReducer;