import React from "react";
import "./sidebar.scss";
import { useNavigate } from "react-router-dom";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import SidebarLogo from "../../../Assets/Images/sidebar-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetLoginUser } from "../../../redux/action/auth";
import { clearAll } from "../../../utils/auth";
import { redirectToExternalUrl } from "../../../utils/utils";
import { ROUTE_URLS } from "../../../constant/routeURLs";
import { FINANCE_PERMISSION, GLOBAL_PERMISSIONS, MENU_PERMISSIONS, SUBMENU_PERMISSIONS, TRIP_PERMISSONS } from "../../../constant/permissions";
import Permissions from "../Permissions";
export default function Sidebar({ children }: IProps) {
  const { permission } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const documentElement: any = document;
  const dispatch = useDispatch();
  // To close sidebar metho
  const closeSidebar = () => {
    if (window.innerWidth > 767) return;
    documentElement.getElementById("mySidebar").style.width = "0";
    documentElement.getElementById("main").style.display = "block";
    documentElement.getElementById("mySidebar").style.marginLeft = "-25px";
  };
  const onSignOut = () => {
    dispatch(resetLoginUser());
    clearAll();
    closeSidebar();
    navigate("/login");
  };

  // permission?.menu.some( (menu:any) => menu['module'] === 'SYSTEMSETTING' )
  return (
    <div className="icreon-sidebar">
      <div
        id="mySidebar"
        className={`sidebar ${process.env.REACT_APP_KNOCK_ENVIORMENT === 'maverick-stage' ? 'green-bg' : ''}`}
      >
        <div className="mobileSidebarHeader">
          <div className="sidebarLogoSec">
        <img src={SidebarLogo} alt="Maverick Logo" />
          </div>
          <a href={void 0} className="headerCloseBtn" onClick={closeSidebar}>&zwnj;</a>
        </div>
        {/* <Scrollbars className="notificationScrollSec" autoHide
                    renderThumbVertical={({ style, ...props }) =>
                        <div style={{ ...style, borderRadius: '4px', backgroundColor: 'rgba(255, 255, 255, 0.1)' ,position: 'relative'}} />}> */}
        <div className="mobileSidebarLinksGroup">
          {permission?.menu?.some((menu: any) => (menu["module"] === MENU_PERMISSIONS.DASHBOARD) && menu["subModule"].includes(SUBMENU_PERMISSIONS.DASHBOARD)) && (
            <div
              className="leftMenuLink"
              onClick={() => {
                navigate(ROUTE_URLS.DASHBOARD);
                closeSidebar();
              }}
            >
              <span className="menu1 navIcon"></span>
              <label>Dashboard</label>
            </div>
          )}

          {permission?.menu?.some((menu: any) => menu["module"] === MENU_PERMISSIONS.CONTACTS) ? (
            <div
              className="leftMenuLink"
              onClick={() => {
                navigate(ROUTE_URLS.CONTACT_LIST);
                closeSidebar();
              }}
            >
              <span className="menu2 navIcon"></span>
              <label>Contacts</label>
              {permission?.permissions[0].includes(GLOBAL_PERMISSIONS.CREATECONTACT) && (
                <Tooltip
                  style={{ zIndex: 10031 }}
                  openDelay={100}
                  anchorElement="target"
                  position="top"
                >
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(ROUTE_URLS.CONTACT_LIST, {
                        state: { addContact: true },
                      });
                      closeSidebar();
                    }}
                    className="createBtn"
                    title="Create Contact"
                  />
                </Tooltip>
              )}
            </div>
          ) : (
            <></>
          )}

          {permission?.menu?.some((menu: any) => menu["module"] === "TRIPS") && permission?.permissions[0].includes(GLOBAL_PERMISSIONS.VIEWTRIP) ? (
            <div
                className="leftMenuLink"
                onClick={() => {
                    navigate(ROUTE_URLS.TRIP);
                    closeSidebar();
                }}>
              <span className="menu3 navIcon"></span>
              <label>Trips</label>
              {permission?.permissions[0].includes(TRIP_PERMISSONS.CREATE_TRIP) && (
              <Tooltip
                style={{ zIndex: 10031 }}
                openDelay={100}
                anchorElement="target"
                position="top"
              >
                <Button
                  className="createBtn"
                  title="Create Trip"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(ROUTE_URLS.CREATE_TRIP);
                    closeSidebar();
                  }}
                />
              </Tooltip>)}
            </div>
          ) : (
            <></>
          )}

          {permission?.menu?.some((menu: any) => (menu["module"] === "FINANCIAL" || menu["module"] === "JC")) ? (
            <div className="leftMenuLink">
              <PanelBar className="subDropdownMenu">
                <PanelBarItem
                  expanded={false}
                  title={
                    <>
                      <span className="menu5 navIcon"></span>
                      <label>Finances</label>
                    </>
                  }
                >
                  <div className="leftSubMenu">
                    <ul>
                      {permission?.menu?.some((menu: any) => menu["module"] === "JC") &&
                      (permission?.permissions[0].includes("VIEWJCAGR") ||
                        permission?.permissions[0].includes("VIEWPROMO")) ? (
                        <li
                          onClick={() => {
                            navigate("/jetcard-list");
                            closeSidebar();
                          }}
                        >
                          Jet Card Agreements
                        </li>
                      ) : (
                        <></>
                      )}
                      {permission?.permissions[0].includes(FINANCE_PERMISSION.VIEWTRIPFINANCELIST) && (<li
                        onClick={() => {
                          navigate(ROUTE_URLS.FINANCE_TRIP_ADMINISTRATION);
                          closeSidebar();
                        }}
                      >
                        Trip Administration
                      </li>)}
                      {permission?.permissions[0].includes(FINANCE_PERMISSION.VIEWOPERATORFINANCE) && (<li
                        onClick={() => {
                          navigate(ROUTE_URLS.FINANCE_OPERATOR_PAYMENT);
                          closeSidebar();
                        }}
                      >
                        Operator Finance
                      </li>)}
                      {/* <li
                        onClick={() =>
                          redirectToExternalUrl(
                            `${process.env.REACT_APP_CRM1_URL}/finances/operators`
                          )
                        }
                      >
                        Operator Payments
                      </li>
                      <li
                        onClick={() =>
                          redirectToExternalUrl(
                            `${process.env.REACT_APP_CRM1_URL}/financial/trips`
                          )
                        }
                      >
                        Trip Administration
                      </li> */}
                    </ul>
                  </div>
                </PanelBarItem>
              </PanelBar>
            </div>
          ) : (
            <></>
          )}

          {Permissions(["VIEWAIRPORT","VIEWAIRCRAFT","VIEWOPERATOR"]) && (
            <div className="leftMenuLink">
              <PanelBar className="subDropdownMenu">
                <PanelBarItem
                  expanded={false}
                  title={
                    <>
                      <span className="menu12 navIcon"></span>
                      <label>Aviation</label>
                    </>
                  }
                >
                  <div className="leftSubMenu">
                    <ul>
                    {Permissions(["VIEWAIRPORT"]) && (
                          <li  onClick={() => {
                              navigate("/airports-list");
                              closeSidebar();
                            }} >
                            Airports
                          </li>
                        )}
                       {Permissions(["VIEWAIRCRAFT"]) && (
                          <li onClick={() => {
                              navigate("/aircrafts-list");
                              closeSidebar();
                            }} >
                            Aircraft
                          </li>
                       )}
                       {Permissions(["VIEWOPERATOR"]) && (
                          <li  onClick={() => {
                              navigate("/operators-list");
                              closeSidebar();
                            }} >
                            Operators
                          </li>
                      )}
                      {Permissions(["VIEWFBO"]) && (
                          <li  onClick={() => {
                              navigate("/fbo-list");
                              closeSidebar();
                            }} >
                            FBO
                          </li>
                      )}
                    </ul>
                  </div>
                </PanelBarItem>
              </PanelBar>
            </div>
          )}

          {permission?.menu?.some((menu: any) => menu["module"] === "INSIGHTS") ? (
            <div
              className="leftMenuLink"
              onClick={() => redirectToExternalUrl(`${process.env.REACT_APP_CRM1_URL}/reports`)}
            >
              <span className="menu6 navIcon"></span>
              <label>Insights</label>
            </div>
          ) : (
            <></>
          )}

          {/* TODO: Apply permission {permission?.menu?.some((menu: any) => menu["module"] == "TASK") ? ( */}
            <div
                className="leftMenuLink"
                onClick={() => {
                    navigate(ROUTE_URLS.TASKS);
                    closeSidebar();
                }}
            >
                <span className="menu7 navIcon"></span>
                <label>Tasks</label>
            </div>

            {permission?.menu?.some((menu: any) => (menu["module"] === MENU_PERMISSIONS.DASHBOARD) && menu["subModule"].includes(SUBMENU_PERMISSIONS.REPORT)) && (
                <div
                    className="leftMenuLink"
                    onClick={() => {
                        if (process.env.REACT_APP_POWER_BI_REPORTS) {
                            redirectToExternalUrl(process.env.REACT_APP_POWER_BI_REPORTS);
                        }
                    }}
                >
                <span className="menu6 navIcon"></span>
                    <label>Reports</label>
                </div>
            )}

          <hr className="d-md-none" />
          {permission?.menu?.some((menu: any) => menu["module"] === "SYSTEMSETTING") ? (
            <div className="leftMenuLink d-md-none">
              <PanelBar className="subDropdownMenu">
                <PanelBarItem
                  expanded={false}
                  title={
                    <>
                      <span className="menu9 navIcon"></span>
                      <label>System Settings</label>
                    </>
                  }
                >
                  <div className="leftSubMenu">
                    <ul>
                      <li
                        onClick={() => {
                          navigate("/system-setting/role-management");
                          closeSidebar();
                        }}
                      >
                        Role Management
                      </li>
                      <li
                        onClick={() => {
                          navigate("/system-setting/team-management");
                          closeSidebar();
                        }}
                      >
                        Team Management
                      </li>
                      <li
                        onClick={() => {
                          navigate("/system-setting/user-management");
                          closeSidebar();
                        }}
                      >
                        User Management
                      </li>
                      <li
                        onClick={() => {
                          navigate("/system-setting/configuration-management");
                          closeSidebar();
                        }}
                      >
                        Configuration Management
                      </li>
                    </ul>
                  </div>
                </PanelBarItem>
              </PanelBar>
            </div>
          ) : (
            <></>
          )}

          {/* TODO : Uncomment below code in future. */}
          {/* <div
            className="leftMenuLink  d-md-none"
            onClick={() => {
              navigate("/");
              closeSidebar();
            }}
          >
            <span className="menu10 navIcon"></span>
            <label>My Profile</label>
          </div> */}

          <div
            className="leftMenuLink d-md-none"
            onClick={() => {
              onSignOut();
            }}
          >
            <span className="menu11 navIcon"></span>
            <label>Sign Out</label>
          </div>
        </div>
        {/* </Scrollbars> */}
      </div>

      <div id="main">
        <div className="wrapper">{children}</div>
      </div>
    </div>
  );
}
// Props DataType of sidebar
type IProps = {
  /** To close the sidebar method*/
  closeSidebar: () => void;
  /** To open the sidebar method*/
  openSidebar: () => void;
  children: React.ReactNode;
};
//  Props Default value
Sidebar.defaultProps = {
  closeSidebar: () => {},
  openSidebar: () => {},
};
