import { Toast } from "../../components/common/ToastNotification";
import { RESET_TOAST_NOTIFICATION, SET_TOAST_NOTIFICATION } from "../types/ToastNotificationTypes";

type State = {
    toast : Toast | null
}
const initialState:State = {
  toast: null
};

const ToastNotificationReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SET_TOAST_NOTIFICATION:
      return {
        ...state,
        toast: action.payload
      };
      case RESET_TOAST_NOTIFICATION:
        return {
          ...state,
          toast: null
        };

    default:
      return state;
  }
}

export default ToastNotificationReducer;