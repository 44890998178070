import {
  HEADER_PERMISSION,
  RESET_LOGIN_USER_DATA,
  SET_LOGIN_USER_DATA,
  SET_USERAZURAD,
} from "../../constant/redux-const";

const initialState = {
  authUser: null,
  permission: {},
  userAzurAD: {},
};

const AuthReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_LOGIN_USER_DATA:
      return {
        ...state,
        authUser: action.payload,
      };
    case RESET_LOGIN_USER_DATA:
      return {
        ...state,
        authUser: null,
      };

    case HEADER_PERMISSION:
      return {
        ...state,
        permission: action.payload,
      };

    case SET_USERAZURAD:
      return {
        ...state,
        userAzurAD: action.payload,
      };

    default:
      return state;
  }
};
export default AuthReducer;
