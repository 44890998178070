import {SET_GLOBAL_SEARCH_TEXT, SET_GLOBAL_SEARCH_RESULTS, SET_IS_SEARCHING_TRUE, CLEAR_GLOBAL_SEARCH_RESULT_AND_TEXT, SET_GLOBAL_SEARCH_CODE, SET_GLOBAL_SEARCH_LOADING, SET_GLOBAL_SEARCH_CATEGORY} from "../types/GlobalSearchTypes";

export const setGlobalSearchCode: any = (payload: string) => async (dispatch: any) => {
    await dispatch({type: SET_GLOBAL_SEARCH_CODE, payload: payload});
};

export const setGlobalSearchText: any = (payload: string) => async (dispatch: any) => {
    await dispatch({type: SET_GLOBAL_SEARCH_TEXT, payload: payload});
};

export const setGlobalSearchResults: any = (payload: object | null) => async (dispatch: any) => {
    await dispatch({type: SET_GLOBAL_SEARCH_RESULTS, payload: payload});
};

export const setIsSearchingTrue: any = (payload: boolean) => async (dispatch: any) => {
    await dispatch({type: SET_IS_SEARCHING_TRUE, payload: payload});
};

export const clearGlobalSearchResultAndText: any = () => async (dispatch: any) => {
    await dispatch({type: CLEAR_GLOBAL_SEARCH_RESULT_AND_TEXT});
};

export const setGlobalSerachLoading: any = (payload: boolean) => async (dispatch: any) => {
    await dispatch({type: SET_GLOBAL_SEARCH_LOADING, payload});
};

export const setGlobalSearchCategory: any = (payload: string) => async (dispatch: any) => {
    await dispatch({type: SET_GLOBAL_SEARCH_CATEGORY, payload: payload});
};