import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducer/root-reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['formikData','formikManageTrip']
};

const pReducer = persistReducer(persistConfig, rootReducer);

const initialState:any = {};

const middleware = [thunk];
const {__REDUX_DEVTOOLS_EXTENSION__} :any=Window
const store = createStore(
  pReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    // eslint-disable-next-line no-underscore-dangle
    __REDUX_DEVTOOLS_EXTENSION__ ? __REDUX_DEVTOOLS_EXTENSION__() : (f:any) => f
  )
);

const persistor = persistStore(store);

export { persistor, store };