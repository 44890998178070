import { deleteCookie, getCookie, setCookie } from "./utils";

export enum LOCALSTORAGE_KEY {
    TOKEN = "token",
    REFRESH_TOKEN = "refresh_token",
    AD_TOKEN = "ad_token",
    LAST_API_TIMESTAMP = "last_api_timestamp"
}
const jets_access_token = process.env.REACT_APP_SSO_KEY + "_jets.access_token";
const jets_ad_access_token = process.env.REACT_APP_SSO_KEY + "_jets.ad_access_token";
const jets_refresh_token = process.env.REACT_APP_SSO_KEY + "_jets.refresh_token";
let domain = window.location.hostname === "localhost" ? "localhost" : ".jets.com";
const options = {
    path: "/",
    domain: domain,
    sameSite: true,
};

export const isUserAuthenticated = () => {
    return localStorage.getItem(LOCALSTORAGE_KEY.TOKEN)
}

export const getLocalStorageByKey = (key: string) => {
    return localStorage.getItem(key)
}

export const setLocalStorageByKey = (key: string, token: string) => {
    localStorage.setItem(key, token)
}

export const clearAll = () => {
    localStorage.clear();
    deleteCookie(jets_access_token,domain);
    deleteCookie(jets_ad_access_token,domain);
    deleteCookie(jets_refresh_token,domain);
}


export const getMVToken = () => {
    return getCookie(jets_access_token);
};
export const getMVADToken = () => {
    return getCookie(jets_ad_access_token);
};
export const getMVRefresh = () => {
    return getCookie(jets_refresh_token);
};
export const setMVToken = (access_token:String) => {
    setCookie(jets_access_token, access_token, 365, options);
};
export const setMVADToken = (ad_access_token:String) => {
    setCookie(jets_ad_access_token, ad_access_token, 365, options);
};
export const setMVRefresh = (refresh_token:String) => {
    setCookie(jets_refresh_token, refresh_token, 365, options);
};

export const customAPI = async (type:any, url:any, payload:any, config:any) => {
    const response = await fetch(url, {
        method: type,
        body:JSON.stringify(payload),
        headers: { ...config?.headers },
    });
    let data = await response.json();
    if (data.ok) {
        return data;
    } else {
        return data;
    }
};
