import {SET_GLOBAL_SEARCH_TEXT, SET_GLOBAL_SEARCH_RESULTS, SET_IS_SEARCHING_TRUE, CLEAR_GLOBAL_SEARCH_RESULT_AND_TEXT, SET_GLOBAL_SEARCH_CODE, SET_GLOBAL_SEARCH_LOADING, SET_GLOBAL_SEARCH_CATEGORY} from "../types/GlobalSearchTypes";

type State = {
    globalSearchText: string;
    globalSearchResults: object | null;
    isSearching: boolean;
    searchCode: string;
    globalStateSearching: boolean
    globalSearchCategory: Array<any>
};
const initialState: State = {
    globalSearchText: '',
    globalSearchResults: null,
    isSearching: false,
    searchCode: "",
    globalStateSearching: false,
    globalSearchCategory: []
};

const GlobalSearchReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_GLOBAL_SEARCH_CODE: 
            return {
                ...state,
                searchCode: action.payload
            }
        case SET_GLOBAL_SEARCH_TEXT:
            return {
                ...state,
                globalSearchText: action.payload,
            };
        case SET_GLOBAL_SEARCH_RESULTS:
            return {
                ...state,
                globalSearchResults: action.payload,
            };
        case SET_IS_SEARCHING_TRUE:
            return {
                ...state,
                isSearching: action.payload
            };
        case CLEAR_GLOBAL_SEARCH_RESULT_AND_TEXT:
            return {
                ...state,
                globalSearchText: '',
                globalSearchResults: null,
            };
        case SET_GLOBAL_SEARCH_LOADING:
            return {
                ...state,
                globalStateSearching: action.payload
            }
        case SET_GLOBAL_SEARCH_CATEGORY:
            return {
                ...state,
                globalSearchCategory: action.payload
            }
        default:
            return state;
    }
};

export default GlobalSearchReducer;
