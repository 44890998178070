import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import { ROUTE_URLS } from "../../../constant/routeURLs";
import Loader from "../../../components/common/Loader";

interface IContactDetailHeader {
    clientName: string;
    preferredEmail?: string | null;
};

const ContactDetailHeader: React.FC<IContactDetailHeader> = ({ clientName, preferredEmail }) => {
    const { contactId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isApiLoading, setIsApiLoading] = React.useState(false);
    
    return (
        <div className="contactTopTitleSec">
            {isApiLoading && <Loader />}
            <div className="row align-items-center">
                <div className="col-5">
                    <div className="titleSec">
                        <h1
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <span className="backScreen"></span> Contact
                        </h1>
                    </div>
                </div>
                <div className="col-7 text-end">
                    <div className="rightBtnGroup">
                        <div className="flexCenterGap mobileReverse">
                            <Button
                                fillMode="outline"
                                themeColor="primary"
                                onClick={() => {
                                    navigate(ROUTE_URLS.CREATE_TRIP, {
                                        state: {
                                            client: {
                                                id: Number(contactId),
                                                name: clientName,
                                                email: preferredEmail ? preferredEmail : null,
                                            },
                                        },
                                    });
                                }}
                            >Create Trip</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default React.memo(ContactDetailHeader);
