import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { setToastNotification } from "../../../redux/action/ToastNotificationAction";
import { IFullTripDetails, ILeg, ITripFeedbackDetail, fetchTripFeedback, sendTripFeedbackFormEmail } from "../../../service/trip";
import { redirectToExternalUrl } from "../../../utils/utils";
import { ROUTE_URLS, buildURL } from "../../../constant/routeURLs";
import { FINANCE_PERMISSION, TRIP_PERMISSONS } from "../../../constant/permissions";
import { TRIP_LEG_STATUS_MAP_LIST, TRIP_STATUS_MAP_LIST } from "../../../constant/constant";
import { TRIP_LEG_STATUS_KEY, TRIP_STATUS_KEY } from "../../../constant/enum";
import { URLS } from "../../../utils/url";
import FlightAwareModal from "./FlightAwareModal";
import Loader from "../../../components/common/Loader";
import CancelTrip from "../common/cancelTrip";

interface ITripDetailsHeader {
  tripDetail: IFullTripDetails | null;
}

const availableLegStatuses = [
    TRIP_LEG_STATUS_MAP_LIST[TRIP_LEG_STATUS_KEY.ONGOING_TRIP].id,
    TRIP_LEG_STATUS_MAP_LIST[TRIP_LEG_STATUS_KEY.IN_TRANSIT].id,
    TRIP_LEG_STATUS_MAP_LIST[TRIP_LEG_STATUS_KEY.COMPLETED].id,
];
const TripDetailsHeader: React.FC<ITripDetailsHeader> = ({ tripDetail }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { permission } = useSelector((state: any) => state.auth);
    const [isApiLoading, setIsApiLoading] = React.useState(false);
    const [showFlightAware, setShowFlightAware] = React.useState(false);
    const [tripFeedbackDetails, setTripFeedbackDetails] = React.useState<ITripFeedbackDetail | null>(null);

    React.useEffect(() => {
        if (tripDetail) {
            fetchTripFeedback(tripDetail.id, (tripFeedbackDetail, isError) => {
                setIsApiLoading(false);
                if (isError) {
                    dispatch(setToastNotification({type: "error", message: "Oops! Something went wrong."}));
                } else {
                    setTripFeedbackDetails(tripFeedbackDetail);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ tripDetail ]);

    const flightAwareDetails: { disable: boolean; available: number; firstLeg: ILeg | null } = useMemo(() => {
        let disable = true;
        let firstLeg: ILeg | null = null;
        let available = 0;
        tripDetail?.legs.forEach((leg) => {
            if (leg.legStatus?.id && availableLegStatuses.includes(leg.legStatus.id)) {
                disable = false;
                available += 1;
                if (!firstLeg) {
                    firstLeg = leg;
                }
            }
        });
        return { disable, available, firstLeg };
    }, [tripDetail]);

    const isTripCompleted = React.useMemo(() => {
        let isCompleted = true;
        if (tripDetail) {
            tripDetail.legs.forEach((leg) => {
                if (leg.legStatus?.id !== TRIP_LEG_STATUS_MAP_LIST[TRIP_LEG_STATUS_KEY.COMPLETED].id) {
                    isCompleted = false;
                    return;
                }
            });
        } else {
            isCompleted = false;
        }
        return isCompleted;
    }, [tripDetail]);

    const onSubmitTripReport = React.useCallback(() => {
        redirectToExternalUrl(URLS.SubmitTripReport);
    }, []);

    const handleCaptureFeedbackClick = React.useCallback(() => {
        if (tripDetail) {
            setIsApiLoading(true)
            sendTripFeedbackFormEmail(tripDetail.id, (_, isError) => {
                setIsApiLoading(false);
                if (isError) {
                    dispatch(setToastNotification({type: "error", message: "Oops! Something went wrong."}));
                } else {
                    dispatch(setToastNotification({type: "success", message: `Feedback Form Sent Successfully to the Client for Trip ID ${tripDetail.tripId}`}));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ tripDetail ]);

    const handleReviewFeedbackClick = React.useCallback(() => {
        if (tripDetail) {
            window.open(
                buildURL(ROUTE_URLS.TRIP_FEEDBACK_BROKER, {tripId: tripDetail.id}),
                "_blank"
            );
        }
    }, [tripDetail]);

    const showCancel = ![
      TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.COMPLETED].id,
      TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.FINALIZED].id,
      TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED].id,
      TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_AND_FINALIZED].id,
      TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_WITH_COST].id
    ].includes(tripDetail?.status?.id as any) && tripDetail?.permissions?.[TRIP_PERMISSONS.CANCEL_TRIP];

    const [visibleCancelDialog, setVisibleCancelDialog] = useState(false);
    const toggleCancelConfirmationDialog = () => {
      setVisibleCancelDialog(!visibleCancelDialog);
    }

  return (
    <div className="contactTopTitleSec">
      {isApiLoading && <Loader />}
      <FlightAwareModal
        tripDetail={tripDetail}
        visible={showFlightAware}
        onCloseClickHandler={() => setShowFlightAware(!showFlightAware)}
        onTrackClickHandler={(tailNo: any) => {
          window.open(buildURL(URLS.FlightawareTail, { tailNo }), "_blank");
        }}
      />
      <div className="row align-items-center">
        <div className="col-5">
          <div className="titleSec">
            <h1
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <span className="backScreen"></span> Trips
            </h1>
          </div>
        </div>
        {tripDetail && (
          <div className="col-7 text-end">
            <div className="rightBtnGroup">
              <div className="dropdown moreActionLinks">
                {visibleCancelDialog && tripDetail && (
                  <CancelTrip
                    onSuccess={() => {
                      toggleCancelConfirmationDialog();
                      navigate(ROUTE_URLS.TRIP);
                    }}
                    trip={tripDetail}
                    toggleCancelConfirmationDialog={
                      toggleCancelConfirmationDialog
                    }
                  />
                )}
                <Button fillMode="outline">More Actions</Button>
                <ul className="dropdown-menu">
                  <li>
                    {showCancel && (
                      <a className="dropdown-item" href={void 0} onClick={() => {
                        setVisibleCancelDialog(true);
                      }}>
                        Cancel Trip
                      </a>
                    )}
                  </li>
                  {tripDetail?.permissions[TRIP_PERMISSONS.EDIT_TRIPS] && (
                    <li>
                      <a
                        className="dropdown-item"
                        href={void 0}
                        onClick={() => {
                          navigate(
                            buildURL(ROUTE_URLS.EDIT_TRIP, {
                              tripId: tripDetail?.id,
                            })
                          );
                        }}
                      >
                        Edit Trip Details
                      </a>
                    </li>
                  )}
                  {isTripCompleted && permission.permissions[0].includes(
                      TRIP_PERMISSONS.VIEWTRIPFEEDBACK
                    ) && (
                    <>
                      {tripFeedbackDetails?.feedbackSubmitted ? (
                        <li>
                          <a
                            className="dropdown-item"
                            href={void 0}
                            onClick={handleReviewFeedbackClick}
                          >
                            Review Feedback
                          </a>
                        </li>
                      ) : (
                        <li>
                          <a
                            className="dropdown-item"
                            href={void 0}
                            onClick={handleCaptureFeedbackClick}
                          >
                            Capture Feedback
                          </a>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
              <div className="flexCenterGap mobileReverse">
                {tripDetail?.permissions[FINANCE_PERMISSION.RPTTRIPISSUE] && (
                  <Button
                    fillMode="outline"
                    themeColor="primary"
                    onClick={onSubmitTripReport}
                  >
                    <span>Submit Trip </span>Report
                  </Button>
                )}
                {flightAwareDetails.disable ? (
                  <Tooltip
                    position="bottom"
                    content={() => (
                      <div className="tooltipTblData">
                        <div className="tooltipRow">
                          To view in Flightaware the legs need to be assigned
                        </div>
                      </div>
                    )}
                  >
                    <Button
                      themeColor="primary"
                      title="To view in Flightaware the legs need to be assigned"
                      style={{
                        opacity: flightAwareDetails.disable ? "0.65" : "1",
                      }}
                      className="remove-k-text-pointer"
                    >
                      Open In FlightAware
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={() => {
                      if (flightAwareDetails.available === 1) {
                        window.open(
                          buildURL(URLS.FlightawareTail, {
                            tailNo:
                              flightAwareDetails?.firstLeg?.aircraft
                                ?.tailNumber,
                          }),
                          "_blank"
                        );
                      } else {
                        setShowFlightAware(!showFlightAware);
                      }
                    }}
                    themeColor="primary"
                  >
                    Open In FlightAware
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(TripDetailsHeader);
