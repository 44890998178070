import {
    TRIP_FILTER_CHANGE_HANDLER,
    TRIP_FILTER_LOADED,
    UPDATE_TRIP_OVERVIEW,
    UPDATE_TRIP_FORM_STEPS,
    UPDATE_SELECTED_STEP,
    UPDATE_IS_EDIT_AFTER_SENT,
    UPDATE_TRIP_FORM_STATUS_OBJ_DRAFT,
    UPDATE_TRIP_FORM_STATUS_OBJ_DIRTY,
    UPDATE_TRIP_FORM_DUPLICATE_TRIP,
    RESET_TRIP,
    UPDATE_TRIP_IS_LOADING,
    UPDATE_TRIP_DETAIL,
    RESET_TRIP_DETAIL,
    UPDATE_TRIP_LOADING,
    UPDATE_TRIP_STATUS_OBJ_DRAFT,
    UPDATE_TRIP_STATUS_OBJ_DIRTY
} from "../../constant/redux-const";

export const tripFilterChangeHandler: any = (filter: any) => {
    return {
        type: TRIP_FILTER_CHANGE_HANDLER,
        payload: filter,
    };
};

export const tripStateChangeHandler: any = (tripFilterDataState: any) => {
    return {
        type: TRIP_FILTER_LOADED,
        payload: tripFilterDataState,
    };
};

export const updateTripIsLoading: any = (isLoading: boolean) => {
    return {
        type: UPDATE_TRIP_IS_LOADING,
        payload: isLoading,
    };
};

export const updateTripOverview: any = (tripOverview: any) => {
    return {
        type: UPDATE_TRIP_OVERVIEW,
        payload: tripOverview,
    };
};

export const updateTripFromSteps: any = (stepList: any) => {
    return {
        type: UPDATE_TRIP_FORM_STEPS,
        payload: stepList,
    };
};

export const updateSelectedStep: any = (selectedStep: any) => {
    return {
        type: UPDATE_SELECTED_STEP,
        payload: selectedStep,
    };
};

export const updateIsEditAfterSent: any = (isEditAfterSent: boolean) => {
    return {
        type: UPDATE_IS_EDIT_AFTER_SENT,
        payload: isEditAfterSent,
    };
};

export const updateTripFormStatusObjDraft: any = (draftSubmitCount: number) => {
    return {
        type: UPDATE_TRIP_FORM_STATUS_OBJ_DRAFT,
        payload: draftSubmitCount,
    };
};

export const updateTripFormStatusObjDirty: any = (isDirty: boolean) => {
    return {
        type: UPDATE_TRIP_FORM_STATUS_OBJ_DIRTY,
        payload: isDirty,
    };
};

export const updateTripFormDuplicateTrip: any = (status: boolean) => {
    return {
        type: UPDATE_TRIP_FORM_DUPLICATE_TRIP,
        payload: status,
    };
};

export const updateTripStatusObjDraft: any = (draftSubmitCount: number) => {
    return {
        type: UPDATE_TRIP_STATUS_OBJ_DRAFT,
        payload: draftSubmitCount,
    };
};

export const updateTripStatusObjDirty: any = (isDirty: boolean) => {
    return {
        type: UPDATE_TRIP_STATUS_OBJ_DIRTY,
        payload: isDirty,
    };
};

export const updateTripLoading: any = (isLoading: boolean) => {
    return {
        type: UPDATE_TRIP_LOADING,
        payload: isLoading,
    };
};

export const resetTrip: any = () => {
    return {
        type: RESET_TRIP,
    };
};
export const resetTripDetail: any = () => {
    return {
        type: RESET_TRIP_DETAIL,
    };
};

export const updateTripDetails: any = (tripDetails: any) => {
    return {
        type: UPDATE_TRIP_DETAIL,
        payload: tripDetails,
    };
};