import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { useDispatch, useSelector } from "react-redux";
import { resetToastNotification } from "../../redux/action/ToastNotificationAction";
import { useEffect } from "react";

export type ToastType = "none" | "success" | "error" | "warning" | "info";
export type Toast = {
  type: ToastType;
  message: string;
};

const ToastNotification = () => {
  const dispatch = useDispatch();
  const { toast } = useSelector((state: any) => state.toast);
  useEffect(() => {
    if (toast) {
      setTimeout(() => onClose(), 5000);
    }
  }, [toast]);
  const onClose = () => {
    dispatch(resetToastNotification());
  };
  if (!toast) return null;
  return (
    <div data-testid="notification-container" className="toast-notification">
      <NotificationGroup
        style={{
          right: 10,
          top: 10,
          alignItems: "flex-start",
          zIndex: 99999,
          display: 'flex'
        }}
      >
        <Fade>
          <Notification
            type={{ style: toast.type, icon: true }}
            closable={true}
            onClose={onClose}
          >
            <div dangerouslySetInnerHTML={{__html: toast.message}}></div>
            {/* <span>{toast.message}</span> */}
          </Notification>
        </Fade>
      </NotificationGroup>
    </div>
  );
};
export default ToastNotification;
