import {
  GET_ALL_CONTACT,
  CREATE_CONTACT,
  DELETE_CONTACT,
  GET_PERSONAL_INFORMATION,
  GET_CONTACT_INFORMATION,
  GET_BROKER_INFORMATION,
  GET_SPOUSE_INFORMATION,
  GET_IDENTITY_INFORMATION,
  RESET_CONTACT_DETAIL,
  GET_COUNTRY_LIST_INFORMATION,
  GET_STATE_LIST_INFORMATION,
  SET_SELECTED_TAB_INDEX,
  CONTACT_CHANGE_FILTER,
  CONTACT_CHANGE_DATA_STATE,
} from "../../constant/redux-const";
import { DEFAULT_PAGE_SIZE } from "../../utils/constant";

const initialState = {
  contacts: [],
  personalInformation: {},
  contactInformation: {},
  brokerInformation: {},
  spouseInformation: {},
  identityInformation: [],
  countryAndStateInformation: {
    countryList: [],
    stateList: [],
  },
  selectedTabIndex: 0,
  dataState: {
    sort: [{ field: "created", dir: "desc" }],
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
  },
  contactFilter: {
    name: "",
    status: [],
    type: null,
    broker: null,
    fromDate: null,
    toDate: null,
    source: null,
  }
};

const ContactReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    /** Load value of ToDo items array */
    case GET_ALL_CONTACT:
      return {
        ...state,
        contacts: action.payload,
      };
    /** Load new ToDo value of ToDo items array */

    case CREATE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.concat(action.payload),
      };
    /** Delete particular value of ToDo items array */
    case DELETE_CONTACT: {
      let deletedArray = state.contacts.filter(
        (item: any) => item.id !== action.payload
      );
      return {
        ...state,
        contacts: deletedArray,
      };
    }
    case GET_PERSONAL_INFORMATION:
      return {
        ...state,
        personalInformation: action.payload,
      };
    case GET_CONTACT_INFORMATION:
      return {
        ...state,
        contactInformation: action.payload,
      };
    case GET_BROKER_INFORMATION:
      const brokerInformation = action.payload;
      brokerInformation.broker.lead = brokerInformation.broker.brokers.find((broker: any) => broker?.isLeadBroker);
      return {
        ...state,
        brokerInformation,
      };
    case GET_SPOUSE_INFORMATION:
      return {
        ...state,
        spouseInformation: action.payload,
      };
    case GET_IDENTITY_INFORMATION:
      return {
        ...state,
        identityInformation: action.payload,
      };
    case GET_COUNTRY_LIST_INFORMATION:
      return {
        ...state,
        countryAndStateInformation: {
          ...state.countryAndStateInformation,
          countryList: action.payload,
        },
      };
    case GET_STATE_LIST_INFORMATION:
      return {
        ...state,
        countryAndStateInformation: {
          ...state.countryAndStateInformation,
          stateList: action.payload,
        },
      };
    case SET_SELECTED_TAB_INDEX :
    return{
      ...state,
      selectedTabIndex : action.payload
    }
    case RESET_CONTACT_DETAIL:
      return {
        ...state,
        personalInformation: {},
        contactInformation: {},
        brokerInformation: {},
        spouseInformation: {},
        identityInformation: [],
      };
    case CONTACT_CHANGE_FILTER:
      return {
        ...state,
        contactFilter: {...action.payload},
      };
    case CONTACT_CHANGE_DATA_STATE:
      return {
        ...state,
        dataState: {...action.payload},
      }
    default:
      return state;
  }
};
export default ContactReducer;
