import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { isUserAuthenticated } from "../utils/auth";
import Header from "./header/Header";
import Sidebar from "../components/atoms/sidebar/sidebar";
import { KnockFeedProvider } from "@knocklabs/react-notification-feed";
import NotificationProvider from "../providers/NotificationProvider";
import { fetchTokenSSO } from "../service/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const Layout: React.FC = () => {
  const { permission } = useSelector((state: any) => state.auth);
  const { userAzurAD } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = isUserAuthenticated();
  const oldPathName = window.location.pathname

    React.useEffect(() => {
            fetchTokenSSO(dispatch, (_, isError) => {
                if (!isError) {
                    navigate(oldPathName ? oldPathName : "/");
                }
            });
    }, []);

  return userToken && permission ? (
    <React.Fragment>
      {userAzurAD && (
        <KnockFeedProvider
          apiKey={String(process.env.REACT_APP_KNOCK_API_KEY)}
          feedId={String(process.env.REACT_APP_FEED_ID)}
          userId={userAzurAD?.azure_ad}
        >
          <NotificationProvider />
        </KnockFeedProvider>
      )}
      <Sidebar>
        <Header />
        <div>
          <Outlet />
        </div>
      </Sidebar>
    </React.Fragment>
  ) : (
    <Navigate to={`/login?return_url=${encodeURIComponent(window.location.pathname)}`} replace={true} />
  );
};
export default Layout;
