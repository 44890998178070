import {Toast} from "../../components/common/ToastNotification";
import {RESET_TOAST_NOTIFICATION, SET_TOAST_NOTIFICATION} from "../types/ToastNotificationTypes";

export enum NOTIFICATION_TYPE {
    ERROR = "error",
    SUCCESS = "success",
};

export const ERROR_MESSAGE = "Something went wrong!";

export const setToastNotification: any = (payload: Toast) => async (dispatch: any) => {
    await dispatch({
        type: SET_TOAST_NOTIFICATION,
        payload: payload,
    });
};

export const resetToastNotification: any = () =>  async (dispatch: any) => {
    await dispatch({
        type: RESET_TOAST_NOTIFICATION,
    });
};
