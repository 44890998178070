import React from 'react';

import { MultiSelectTree, MultiSelectTreeChangeEvent, MultiSelectTreeExpandEvent, getMultiSelectTreeValue } from '@progress/kendo-react-dropdowns';
import { processMultiSelectTreeData, expandedState } from "./multiselecttree-data-operations";

const dataItemKey = "id";
const checkField = 'checkField';
const checkIndeterminateField = 'checkIndeterminateField';
const subItemsField = "items";
const expandField = "expanded";
const textField = "text";

const fields = { dataItemKey, checkField, checkIndeterminateField, expandField, subItemsField };

export interface IOption {
    id: unknown;
    text: string;
    key: string;
    items?: Array<IOption>;
}

interface ICustomMultiSelectTreeProps {
    data: Array<IOption>;
    value: Array<IOption>;
    onMultiSelectTreeChange: (items: Array<IOption>) => void;
    onMultiSelectTreeBlur?: () => void;
    isLoading?: boolean;
    otherProps?: {
        placeholder?: string;
        ariaLabelledBy?: string;
        ariaDescribedBy?: string;
        popupSettings?: object;
    };
};
const CustomMultiSelectTree: React.FC<ICustomMultiSelectTreeProps> = ({ data, value, onMultiSelectTreeChange, onMultiSelectTreeBlur, isLoading, otherProps }) => {
    const [ expanded, setExpanded ] = React.useState([ data[0][dataItemKey] ]);

    const onChange = (event: MultiSelectTreeChangeEvent) => {
        const selectedData = getMultiSelectTreeValue(data, {...fields, ...event, value}).map(value => ({
            ...value,
            key: data.find((options) => options.id === value.id)?.key
        }));
        onMultiSelectTreeChange(selectedData);
    }

    const onExpandChange = React.useCallback((event: MultiSelectTreeExpandEvent) => {
        return setExpanded(expandedState(event.item, dataItemKey, expanded));
    }, [expanded]);

    const treeData = React.useMemo(() => {
        return processMultiSelectTreeData(data, { expanded, value, ...fields });
    }, [expanded, value]);

    return (
        <MultiSelectTree
            data={treeData}
            value={value}
            onChange={onChange}
            onBlur={onMultiSelectTreeBlur ?? undefined}
            loading={isLoading ?? undefined}
            onExpandChange={onExpandChange}
            textField={textField}
            dataItemKey={dataItemKey}
            checkField={checkField}
            checkIndeterminateField={checkIndeterminateField}
            subItemsField={subItemsField}
            expandField={expandField}
            placeholder={otherProps?.placeholder ?? undefined}
            ariaLabelledBy={otherProps?.ariaLabelledBy ?? undefined}
            ariaDescribedBy={otherProps?.ariaDescribedBy ?? undefined}
            popupSettings={otherProps?.popupSettings ?? undefined}
        />
    );
}

export default React.memo(CustomMultiSelectTree);