import {
  GET_ALL_ROLES,
  GET_ALL_USERS,
  GET_ALL_TEAMS,
  CREATE_TEAM,
  DELETE_TEAM,
  GET_ALL_PRIVILAGES,
  URT_USER_CHANGE_FILTER,
  URT_USER_CHANGE_DATA_STATE,
} from "../../constant/redux-const";
import { DEFAULT_PAGE_SIZE } from "../../utils/constant";

const initialState = {
  items: [],
  user: [],
  team: [],
  role: [],
  account: [],
  permission: [],
  userDataState: {
    sort: [{ field: "userName", dir: "asc" }],
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
  },
  userFilter: {
    name: "",
    status: "",
    toDate: null,
    fromDate: null,
    role: [],
    team: [],
  }
};

const URTReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    /** Load value of ToDo items array */
    case GET_ALL_TEAMS:
      return {
        ...state,
        team: action.payload,
      };
    /** Load new ToDo value of ToDo items array */

    case CREATE_TEAM:
      return {
        ...state,
        items: state.items.concat(action.payload),
      };
    /** Delete particular value of ToDo items array */
    case DELETE_TEAM: {
      let deletedArray = state.items.filter((item: any) => item.id !== action.payload);
      return {
        ...state,
        items: deletedArray,
      };
    }

    // Role REducer
    case GET_ALL_ROLES:
      return {
        ...state,
        role: action.payload,
      };

    case GET_ALL_PRIVILAGES:
      return {
        ...state,
        permission: action.payload,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        user: action.payload,
      };

    // User Filters
    case URT_USER_CHANGE_FILTER: 
      return {
        ...state, 
        userFilter: action.payload
      }
    case URT_USER_CHANGE_DATA_STATE: 
      return {
        ...state, 
        userDataState: action.payload
      }
    default:
      return state;
  }
};
export default URTReducer;
