export const URLS = {
  SubmitTripReport: `https://forms.office.com/Pages/ResponsePage.aspx?id=k7Mfu5XAokCK_dfJHd9PYino_2HfoZhBnf5BZmvl4bhUOUlESzRURjI4V01TQkQ3U0FVVkJGT1lSMSQlQCN0PWcu`,
  GetLatLng: "https://maps.googleapis.com/maps/api/geocode/json?address=",
  GetTimezone: "https://maps.googleapis.com/maps/api/timezone/json?location=",
  GetIp: "https://api.db-ip.com/v2/free/self",
  FlightawareTail: "https://flightaware.com/live/flight/:tailNo",
  SafeCharge: "https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js",
  FetchToken: "/authenticate",
  FetchTokenSSO: "/authenticate-sso",
  FetchRefreshToken: "/refreshaccesstoken",
  AuthenticateUser: "/authenticate",
  BrokerBulkEdit:"/contacts/brokerinfo",
  BulkResend:'/agreement/jc-terms-conditions/resend/bulk',
  DeleteJetCard: "/jetcard",
  AircraftDelete:"master/aircraft/delete/",
  GetClientCardPreviewDetails:"/jetcard/cardclientpreview",
  GetAgreementPdf: "/agreement/terms-and-conditions/pdfwithuid",
  HubspotJCListing:'/hubspot/inquiry',
  HubspotTripListing: '/hubspot/inquiry/trip',
  GlobalSearch: "/globalsearch",
  GlobalSearchJetCardList: "/globalsearch/jetcard",
  GlobalSearchContactList: "/globalsearch/contact",
  GetNewToken: "/getTokenByRefreshToken",
  CreateContact: "/contacts/client",
  Getallassignedbrokers: "/contacts/getallassignedbrokers",
  GetContacts: "/contacts",
  GetDuplicateClients: "/wall/validate/duplicate-clients",
  CreateJetCard: "/contacts/jetcard",
  GetJetCardPromotion: "/contacts/jetcard/promotions",
  JetCardPromotion: "/contacts/jetcard/promotion",
  UpdatePromotionStatus: "/contacts/jetcard/promotion/status",
  DeletePromotion: "/contacts/jetcard/promotion/delete",
  ValidateContact: "/contacts/client/validate",
  GetClientEmails: "/contacts/client/email/",
  GetUserEmails: "/acl/user/email/",
  GetClientBrokers: "/contacts/brokerinfo/",
  GetClientPassenger: "/passenger/passengers/",
  AuthorizeAgreement: "/agreement/authorizeagreement",
  GetConfiguration: "/configuration/getconfiguration",
  GetTermsAndConditions: "/agreement/terms-and-conditions/gettermandcondition",
  GetMinDailyHours: "/contacts/jetcard/jet-cards-daily-hours",
  GetAirCraftRates: "/contacts/jetcard/jet-cards-type-rate",
  GetTaxSurcharge: "/agreement/jetcard/tax-surchage",
  UpdateJetCardDisplayName: "/agreement/jetcard/displayname",
  GetJetCardDetails: "/agreement/jetcard",
  GetClientCardDetails: "/jetcard/cardclientview",
  JetCardTransaction: "/jetcard/transaction",
  PostJetCardResend: "/agreement/terms-and-conditions/resend",
  GetBrokerAssignmentUserName: "/user/brokerassignment/users",
  GetBrokerAssignment: "/user/brokerassignment",
  GetContactNotesByClientId: "/contacts/notes/client-id",
  UpdateContactNotes: "/contacts/notes",
  UpdateJetCardRates: "/configuration/jet-cards-type-rate",
  GetJetCardIdByContacts: "/contacts/jetcard/dropdown",
  GetTripIdByContacts: "/trip/trip/dropdown",
  GetContactJetCardList: "/contacts/jetcard/overview",
  GetContactBillingAndClientNotes: "/contacts/jetcard/client-notes-and-billing-notes",
  UpdateJetCardBalanceStatement: "/jetcard/balance-statement",
  GetTripListing: "/trip",
  GetGlobalTripListing: "/globalsearch/trip",
  GetTripLegs: "/trip/legs",
  TripBulkEdit:"/trip/bulk/brokers",
  GetTripAirports:"/trip/airports",
  Wyvernrating:"/master/wyvernrating",
  Priorityrating:"/master/priorityrating",
  Phonetype:"/master/phonetype",
  Argusrating:"/master/argusrating",
  Aircrafttype:"/master/aircrafttype",
  Emailtype:"/master/emailtype",
  AvinodeidExist:"/master/operator/avinodeidexist",
  GetPaxCapacity : "master/aircraft/paxcapacity",
  GetTripAircraft:"/trip/aircraft",
  GetTripOperator:"/trip/operator",
  GetTripFBO:"/trip/fbo",
  GetSpecialRequestTypes:"/trip/specialrequesttypes",
  GetTripOverview: "/trip/:tripId",
  GetTripLegsByTripId: "/trip/scenariolegs/:tripId",
  GetTripSpecialRequestByTripId: "/trip/specialrequest/:tripId",
  PostTripOverview: "/trip/create",
  PutTripOverview: "/trip/update",
  GetTripDetailsByTripId: "/trip/detail/:tripId",
  GetFullTripDetailsByTripId: "/trip/detail/:tripId/full",
  GetAcceptedTripDetailsByTripId: "/trip/detail/:tripId/accepted",
  GetTripLegsScenarios:"/trip/legsscenarios/:tripId",
  GetEditTripLegsOptions:"/trip/legsoptions/:tripId",
  CreateOption: "/trip/legsoptions",
  GetJCProposal: "/trip/proposal/jetcard/:tripId",
  GetJCDetails: "/agreement/jetcard/:jcId",
  CreateProposal: "/trip/proposal/jetcard",
  CreateCharterProposal: "/trip/proposal/charter",
  SendTripReminderEmail: "/trip/proposal/reminderemail/:tripId",
  ContactPaymentInformation: "/contacts/paymentinfo/:clientId",
  GetTripDetailsByTripIdAndHash: "/trip/clientreview/:tripId/:hash",
  GetInvoiceDetailsByTripIdAndHash: "/trip/clientreview/invoice/:tripId/:hash",
  GetInvoiceDetailsByTripId: "/trip/invoice/:tripId",
  PostProposalAccepted: "/trip/proposal/accept",
  DownloadTripContract: "/trip/downloadcontract/:tripId",
  DownloadTripContractForClient: "/trip/clientreview/downloadcontract/:tripId/:hash",
  DownloadInvoiceByTripIdAndHash: "/trip/clientreview/invoice/:tripId/:hash/download",
  DownloadInvoiceByTripId: "/trip/invoice/:tripId/download",
  DownloadTripItinerary: "/trip/manage/itinerarydownload",
  DeletePaymentInfoByTripIdAndPayId: "/trip/clientreview/paymentinfo/:tripId/:hash/:payId",
  TripManage: "/trip/manage/makeassignment",
  UpdateTripManageLeg: "/trip/manage/updatelegsassignment",
  TripManageFinanceAdjustment : "/trip/manage/financeadjustment",
  TripManageConfirmSendToFinance : "/trip/manage/statusupdate/:tripId",
  PostFetchFinanceTripList: "/trip/tripsfinanceview",
  GetFinanceSendInvoice: "/trip/manage/sendinvoice/:tripId",
  FinanceOperatorList: "/trip/operator/payments",
  GetFinanceOperatorList: "/trip/operator/payments/:legId",
  CancelTrip : "/trip/cancel",
  PostFetchFinanceOperatorList: "/trip/operator/payments",
  PostAssignPassengerToTrip: "/trip/detail/passengers",
  TripDownloadFiles: "/trip/files/:tripId",
  SendTripFiles: "/trip/files",
  UpdateTripFiles: "/trip/files/upload",
  SendDownloadTripFiles: "/trip/files/download",
  GetTripLegsByIds: "/trip/legs/tripids",
  PutTripReset: "/trip/:tripId",
  GetTripIdListing: "trip/dropdown",
  PostTripSearch: "/trip/dropdown",
  GetEteConfiguration: "/master/eteconfiguration",
  FileuploadUpload: "fileupload/file/upload",
  CreateTask: "/task",
  EditTask: "/task",
  GetTaskList: "/task",
  GetSendTripFeedbackEmail: "/trip/feedback/email/:tripId",
  GetTripSubmitFeedback: "/trip/feedback/:tripId",
  GetTripSubmitFeedbackWithHash: "trip/feedback/:tripId/submit-feedback/:hash",
  PostTripSubmitFeedbackWithHash: "/trip/feedback/:tripId/submit-feedback/:hash",
  GetTaskListByClientId: "/task/client/:clientId",
  ChangeTaskStatus: "/task/status",
  GetTaskListByTripId: "/task/trip/:tripId",
  DeleteTask: "/task/:taskId",
  GetActivityHistoryById: "/configuration/jet-cards-type-rate/auditlog/:jetCardTypeRateId",
  GetTripActivityLog: "trip/:tripId/activitylog",
  GetTripLegActivityLog: "trip/leg/:legId/activitylog",
  GetTaskListByFilter: "task/filter",
  GetTripCount: "trip/client/:clientId/tripcount",
  GetPaymentSession: "/trip/:tripId/nuveisession/:hash",
  VisitNotification: "/notification/contactnotifications/visit",
  GetNotifications: "/notification/contactnotifications",
  GetActivityLogBroker: "/acl/user/:userId/activitylog",
  GetPersonalInfoActivityLog: "contacts/personalinfo/activitylog/:contactId",
  GetIdentityInformationActivityLog: "contacts/identityinformation/activitylog/:contactId",
  GetClientActivityLog: "contacts/client/activitylog/:contactId",
  GetSpouseInfoActivityLog: "contacts/spouseinfo/activitylog/:contactId",
  //   Emails Trigger
  GetTriggerJetCardAgreementViewEmail: "agreement/jetcard/clientreviewactivity/:jetCardId",
  GetTriggerTripProposalViewEmail: "/trip/clientreview/:tripId/activity",
  GetWeatherPoint: "https://api.weather.gov/points/:lat,:lng",
  //   PDF Download
  GetJetCardTermsPDFByAgreementId: "/agreement/terms-and-conditions/download/:agreementId",
  GetTripTermsPDFByTripId: "/trip/terms-and-conditions/download/:tripId",
  GetDownloadedJetCardContractPDFUrl: "/jetcard/authorizeagreement/jetcardcontracts/:jetCardId",
  GetReportsById: "/reports",
  PostFrontendError: "/frontend-error",
};

export const buildAPIURL = (url: string, paramReplacements?: Record<string, any>, queryParam?: Record<string, any>) => {
    if (paramReplacements) {
        url = replaceParams(url, paramReplacements);
    }
    if (queryParam) {
        url = addQueryParameters(url, queryParam);
    }
    return url;
};

const replaceParams = (url: string, params: Record<string, any>) => {
    Object.keys(params).forEach(k => {
        url = url.replace(`:${k}`, encodeURIComponent(params[k]));
    });
    return url;
};

export const addQueryParameters = (url: string, params: Record<string, any>) => {
    const queryList: Array<string> = [];
    Object.keys(params).forEach(k => {
        queryList.push(`${k}=${encodeURIComponent(params[k])}`);
    });
    const prefixCharacter = url.indexOf("?") === -1 ? "?" : "&";
    return `${url + prefixCharacter + queryList.join("&")}`;
};
