import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import TagManager from 'react-gtm-module'
import { store, persistor } from "./redux/store";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENABLED && process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
    }
    TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
        <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
