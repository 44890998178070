import * as type from '../../constant/redux-const';

const initialState: any = {
  primaryNotifications: [],
  notifications: [],
  totalUnread: 0,
  page: 0,
  size: 20,
  totalLength: 0
};

const NotificationReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case type.UPDATE_NOTIFICATION:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}


export default NotificationReducer;
